<template lang="pug">
om-modal.new-campaign-flow-book.new-campaign-flow-modal(
  :name="modalName"
  scrollable
  width="75%"
  :clickToClose="false"
  @closeClicked="close"
  @closed="close"
  @opened="onOpened"
)
  template(#header-action-icon)
    .brand-modal-action-icon
      close-icon.cursor-pointer(
        @click.native="onCloseClick"
        :width="14"
        :height="14"
        color="#C2C2C2"
      )
  template(#modal-body)
    .new-campaign-flow-modal-block.row
      .col.new-campaign-flow-steps
        .row.new-campaign-flow-step
          .col-auto
            .new-campaign-flow-step-circle.done
              UilCheck(size="36")
          .col
            om-all-caps-text(bold orange) {{ $t('step', { step: 1 }) }}
            om-heading(h5) {{ $t('newCampaignFlow.websiteOptimization.book.steps.0.title') }}
        .row.new-campaign-flow-step
          .col-auto
            .new-campaign-flow-step-circle.active
              UilComments(size="28")
          .col
            om-all-caps-text(bold orange) {{ $t('step', { step: 2 }) }}
            om-heading(h5) {{ $t('newCampaignFlow.websiteOptimization.book.steps.1.title') }}
            om-heading.mt-32.mb-32(
              h3
              v-html="$t('newCampaignFlow.websiteOptimization.book.steps.1.details.title')"
            )
            om-heading.mb-4(h5) {{ $t('newCampaignFlow.websiteOptimization.book.steps.1.details.subtitle') }}
            .d-flex.align-items-center.mb-3(
              v-for="option in $t('newCampaignFlow.websiteOptimization.book.steps.1.details.options')"
            )
              UilCheckCircle.om-alert-green.mr-1(size="24")
              span {{ option }}
      .col-6.col-xl-5.booking-sw-wrapper.meetings-iframe-container(
        :data-src="eventUrlWithParams"
        :class="{ 'booking-done': isRequested }"
      )
  template(#modal-footer)
    .d-flex.justify-content-end
      om-button(v-if="isRequested" primary @click="goToDashboard()") {{ $t('done') }}
</template>

<script>
  import { mapState, mapMutations } from 'vuex';
  import SAVE_AI_FEATURE_REQUEST from '@/graphql/SaveAiFeatureRequest.gql';
  import { UilCheck, UilComments, UilRocket, UilCheckCircle } from '@iconscout/vue-unicons';
  import modalsMixin from '@/mixins/newCampaignFlow/modals';

  const HUBSPOT_WIDGET_URL = 'https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js';
  const HUBSPOT_EVENT_LINK = 'https://meetings.hubspot.com/ai-demo/booking';

  export default {
    components: { UilCheck, UilComments, UilRocket, UilCheckCircle },
    mixins: [modalsMixin],
    data: () => ({ modalName: 'new-campaign-flow-book' }),
    computed: {
      ...mapState(['account']),
      eventUrlWithParams() {
        const link = new URL(HUBSPOT_EVENT_LINK);

        link.searchParams.append('embed', 'true');

        this.addPersonalDataToEventURL(link.searchParams);

        return link.toString();
      },
      currentUser() {
        return this.account?.login;
      },
      firstName() {
        return this.currentUser?.firstName;
      },
      lastName() {
        return this.currentUser?.lastName;
      },
      email() {
        return this.currentUser?.email;
      },
      selectedFeatureName() {
        return this.$t(`newCampaignFlow.websiteOptimization.${this.type}.${this.about}.title`);
      },
      currentAIRequestKey() {
        return `${this.about}_newCampaignFlow`;
      },
      isRequested() {
        if (!this.account.profile) return false;

        const keys = Object.keys(this.account.profile);
        const hasProfileKey = keys.some((key) => key.includes(`${this.about}_newCampaignFlow`));
        if (this.isSAB) return !this.hasSmartAbTestFeature ? hasProfileKey : false;
        return hasProfileKey;
      },
    },
    mounted() {
      window.addEventListener('message', this.hubspotEventListener, false);
    },
    beforeDestroy() {
      window.removeEventListener('message', this.hubspotEventListener);
    },
    methods: {
      ...mapMutations(['setProfileKey']),
      onOpened() {
        this.$emit('track', { name: 'book-open', properties: { value: this.about } });
        this.appendHubSpotCode();
      },
      appendCode() {
        const scr = document.createElement('script');
        scr.src = HUBSPOT_WIDGET_URL;
        scr.type = 'text/javascript';
        document.querySelector('body')?.appendChild?.(scr);
      },
      appendHubSpotCode() {
        const scr = document.querySelector('script[src^="https://static.hsappstatic.net"]');
        if (!scr) {
          this.appendCode();
        } else {
          scr.remove();
          this.appendCode();
        }
      },
      async saveAiEarlyAccessRequest() {
        this.isAiEarlyAccessLoading = true;
        await this.$apollo.mutate({
          mutation: SAVE_AI_FEATURE_REQUEST,
          variables: { feature: this.currentAIRequestKey },
        });
        this.isAiEarlyAccessLoading = false;
      },
      preSetProfileKey() {
        this.setProfileKey({ key: `aiRequest_${this.currentAIRequestKey}`, value: new Date() });
      },
      isBookSucceeded(event) {
        return (
          event?.origin === 'https://meetings.hubspot.com' && event?.data?.meetingBookSucceeded
        );
      },
      async hubspotEventListener(event) {
        if (this.isBookSucceeded(event)) {
          this.$emit('track', { name: 'book-succeeded', properties: { value: this.about } });
          await this.saveAiEarlyAccessRequest();
          this.preSetProfileKey();
          this.close(false);
        }
      },
      close(tracking = true) {
        if (tracking) {
          this.$emit('track', { name: 'book-close', properties: { value: this.about } });
        }
        this.$emit('close');
      },
      goToDashboard() {
        if (this.$route.name === 'dashboard') {
          this.$modal.hide(this.modalName);
        } else {
          this.$router.push({ name: 'dashboard' });
        }
      },
      addPersonalDataToEventURL(searchParams) {
        const { firstName = null } = this.account?.login ?? {};
        if (firstName) searchParams.append('firstname', firstName);

        const { lastName = null } = this.account?.login ?? {};
        if (lastName) searchParams.append('lastname', lastName);

        const { email = null } = this.account?.login ?? {};
        if (email) searchParams.append('email', email);

        const { phoneNumber = null } = this.account?.login ?? {};
        if (phoneNumber) {
          searchParams.append('phone', phoneNumber);
          searchParams.append('mobilephone', phoneNumber);
        }

        const domains = this.account?.settings?.domains || [];
        if (domains.length === 1 && domains?.[0]?.domain?.trim?.()?.length) {
          searchParams.append('domain', domains[0].domain.trim());
        }
      },
    },
  };
</script>

<style lang="sass">
  @import "@/sass/variables/_colors.sass"
  .new-campaign-flow-book
    &.v--modal-overlay:not(.centered-modal) .v--modal
      top: inherit !important
    .v--modal-background-click
      display: flex
      justify-content: center
      align-items: center
    &.v--modal-overlay.scrollable .v--modal-box
      margin-bottom: 2.5rem !important
      margin-top: 2.5rem !important
    .v--modal-box,
    .brand-modal.brand-modal-light,
    .brand-modal-header
      border-radius: 1.25rem
    .brand-modal .brand-modal-body
      padding: 0 2rem !important
    .brand-modal .brand-modal-footer
      padding: 1rem 2rem 2.125rem !important
      margin-bottom: 0
    .booking-sw-wrapper
      iframe
        width: 100%
        min-height: 615px !important
        height: 100% !important
    .booking-done
      iframe
        min-height: 450px !important
    .v--modal
      max-width: 66.25rem
    .mb-32
      margin-bottom: 2rem
    .mt-32
      margin-top: 2rem
    .om-alert-green
      color: $om-alert-green-500
    .new-campaign-flow-modal-block
      position: relative
    .new-campaign-flow
      &-steps
        height: max-content
        &:before
          position: absolute
          top: 0
          left: 0
          content: ""
          height: 90%
          width: 72px
          background-image: url('~@/assets/admin/img/new-campaign-flow/dashed-line.png')
          background-repeat: no-repeat
          background-position: center
          background-size: contain
      &-step
        &:last-child > div
          background: white
        & + .new-campaign-flow-step
          margin-top: 2rem
        &-circle
          width: 3.25rem
          height: 3.25rem
          background: $om-peach-200
          color: $om-orange-500
          border-radius: 100%
          display: flex
          align-items: center
          justify-content: center
          &.active
            background: $om-orange-500
            color: white
          &.done
            background: $om-alert-green-500
            color: white
</style>
